import { ref } from '@vue/composition-api'
import router from '@/router'
import axiosClient from '@/helpers/axios'

import useAlertNotification from '@/services/notification/useAlert'

export default function useTypeExperts() {
  const { errorToast, successToast } = useAlertNotification()
  const typeExpert = ref([])
  const expert = ref([])
  const rating = ref([])
  const loader = ref(false)
  const typeExpertProcess = ref(false)
  const typeExpertSuccess = ref(false)
  const typeExperts = ref([])
  const errors = ref('')

  // Liste des type_experts
  const getTypeExperts = async () => {
    loader.value = true
    await axiosClient.get('/type_experts').then(response => {
      if (response.data.success === true) {
        loader.value = false
        typeExperts.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  const getExpertById = async () => {
    const response = await axiosClient.get(`/get-expert/${router.currentRoute.params.id}`)
    expert.value = response.data.data
    console.log(expert)
  }

  const getExpertRating = async () => {
    const response = await axiosClient.get(`/get-expert-rating/${router.currentRoute.params.id}`)
    rating.value = response.data.data
    console.log(rating)
  }

  // Obtenir un typeExpert
  const getTypeExpert = async slug => {
    const response = await axiosClient.get(`/type_experts/${slug}`)
    typeExpert.value = response.data.data
  }
  // Ajouter une typeExpert
  const createTypeExpert = async data => {
    errors.value = ''
    typeExpertProcess.value = true
    await axiosClient.post('/type_experts', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          typeExpertSuccess.value = true
          typeExpertProcess.value = false
          getTypeExperts()
        }
      })
      .catch(error => {
        typeExpertProcess.value = false
        typeExpertSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errors.value = error.response.data.errors
          const responseErrors = error.response.data.errors
          Object.keys(responseErrors).forEach(key => {
            errorToast('Oups erreur !', responseErrors[key][0])
          })
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une typeExpert
  const updateTypeExpert = async data => {
    errors.value = ''
    typeExpertProcess.value = true
    await axiosClient.patch(`/type_experts/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          typeExpertSuccess.value = true
          typeExpertProcess.value = false
          getTypeExperts()
        }
      })
      .catch(error => {
        typeExpertProcess.value = false
        typeExpertSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    typeExpert,
    typeExperts,
    getTypeExperts,
    typeExpertProcess,
    createTypeExpert,
    updateTypeExpert,
    getTypeExpert,
    loader,
    getExpertRating,
    getExpertById,
    expert,
    rating,
    typeExpertSuccess,
  }
}
